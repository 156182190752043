<template>
  <main class="p-boat layout--boat" v-if="currentBoatData">
    <AppPageHeader v-bind="headerData" showBackBtn />

    <section class="p-boat__content">
      <ProdImages :images="images" />

      <ProdMeta :price="currentBoatData.price" />

      <section class="p-boat__info content">
        <article class="p-boat__description">
          <h2>Description</h2>
          <div v-html="description" />
        </article>

        <article v-if="features">
          <h2>Features</h2>
          <dl>
            <div v-for="(value, key, ind) in features" :key="ind">
              <dt>{{ key }}</dt>
              <dd>{{ value }}</dd>
            </div>
          </dl>
        </article>
      </section>

      <section class="p-boat__extras content">
        <!-- <h2>Optional Extras</h2>
        <ul>
          <li>Optional Extra</li>
          <li>Optional Extra</li>
        </ul> -->

        <em class="small">
          Options, specifications & Sale prices subject to change without notice. <br />
          Please contact us for the latest information
        </em>
      </section>
    </section>
  </main>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import ProdImages from '@/components/ProdImages'
import ProdMeta from '@/components/ProdMeta'
import { useQuery } from '@/composables/useQuery'

const { data } = useQuery('stock', 'https://api.melbournemalibu.com.au/get-boats')
const route = useRoute()
const currentBoatData = computed(() => data?.value?.find(({ id }) => id === route.params.id))

const headerData = computed(() => {
  const { thumbnail, title, price, year, make, model } = currentBoatData?.value || {}
  let newTitle = model.toLowerCase().includes('wakesetter')
    ? {
      model: 'Wakesetter',
      subModel: model.replace(/wakesetter/gi, '')
    }
    : null

  return {
    headerImg: thumbnail,
    headerAlt: title,
    headerText: price,
    preTitle: `${year} ${make}`,
    title: newTitle ? newTitle.model : null,
    postTitle: newTitle ? newTitle.subModel : model
  }
})

const description = computed(() => {
  if (!currentBoatData.value?.description) return null

  let text = `<p>${currentBoatData.value.description}</p>`
  text = text.replace(/\n/g, '<br />')

  return text
})

const features = computed(() => {
  if (!currentBoatData.value) return null

  const { saleStatus, year, make, model, condition, attributes } = currentBoatData.value

  const convertToTitleCase = str => str.replace(/([A-Z])/g, " $1")
  const attrs = attributes
    .filter(({ name }) => name.toLowerCase() !== 'power')
    .reduce((acc, item) => ({ ...acc, [convertToTitleCase(item.name)]: item.value }), {})

  return {
    Type: saleStatus,
    Condition: condition,
    Make: make,
    Model: model,
    Year: year,
    ...attrs
  }
})

const images = computed(() => {
  if (!currentBoatData.value) return null
  const { title, photos } = currentBoatData.value

  return {
    main: photos[0],
    alt: `${title} photo 0`,
    thumbs: [
      ...photos.map((photo, index) => ({ src: photo, alt: `${title} photo ${index}` }))
    ]
  }
})
</script>
